<template>
  <div>
    <b-overlay :show="downloadPDFloading" rounded="sm" opacity="0.2" no-fade>
      <b-card class="card_body_bottom">
        <template #header>
          <b-row
            class=" flex-row justify-content-start align-items-center"
            style="width: 100%"
          >
            <b-col
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              class="d-flex justify-content-start align-items-center"
              style="gap:5px"
            >
              <h4 class="mb-0">
                {{ $t("statement.titles.statement") }}
              </h4>
              <b-button
                variant="flat-secondary"
                class="btn-icon rounded-circle"
                :disabled="accountNameSelected == null"
                @click="refreshStatement()"
                v-b-tooltip.hover="'Refresh Statement Report'"
              >
                <feather-icon icon="RefreshCcwIcon" />
              </b-button>
            </b-col>
          </b-row>
        </template>

        <b-card-body>
          <b-row class="align-items-center">
            <!-- account name -->
            <b-col cols="12" md="6" xl="2" lg="2" sm="12">
              <label class="compact_label">{{
                $t("statement.account_name")
              }}</label>
              <v-select
                label="AccountName"
                v-model="accountNameSelected"
                :options="accountNames"
              />
            </b-col>

            <b-col cols="12" md="6" xl="2" lg="2" sm="12"
                  v-if="accountNameSelected && accountNameSelected.AccountName =='SFC P&L'"
                >
                  <b-form-group
                    :label="$t('swap_future.trade_form.cost_center')"
                  >
                
                      <v-select
                        v-model="costCenterSelected"
                        :options="optCostCenters"
                        :clearable="true"
                        :placeholder="
                          $t('swap_future.trade_form.cost_center')
                        "
                        :reduce="(val) => val.CostCenter"
                        label="CostCenter"
                        ref="costCenter"
                      />
                </b-form-group>
              </b-col>
            <!-- start date -->
            <b-col cols="12" md="6" xl="2" lg="2" sm="12">
              <label class="compact_label"
                >{{ $t("statement.start_date") }}
              </label>
              <b-form-datepicker
                v-model="startDate"
                placeholder="Choose a start date"
                :start-weekday="1"
                :disabled="accountNameSelected == null"
                :hide-header="true"
                class="form-control "
                today-button
                reset-button
              />
            </b-col>
            <!-- end date -->
            <b-col cols="12" md="6" xl="2" lg="2" sm="12">
              <label class="compact_label"
                >{{ $t("statement.end_date") }}
              </label>
              <b-form-datepicker
                v-model="endDate"
                placeholder="Choose a end date"
                :start-weekday="1"
                :disabled="accountNameSelected == null"
                :hide-header="true"
                :min="startDate"
                class="form-control"
                today-button
                reset-button
              />
            </b-col>

            <b-col
              cols="12"
              md="6"
              xl="2"
              lg="2"
              sm="12"
              class="d-flex justify-content-start align-items-center"
              style="gap:15px"
            >
              <!-- trade date valuation -->
              <!-- <b-form-group :label="$t('statement.trade_date_valuation')">
                <b-form-checkbox
                  checked="true"
                  v-model="tradeDateCheck"
                  class="custom-control-dark "
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group> -->

              <!-- include reversals -->
              <b-form-group :label="$t('statement.include_revesals')">
                <b-form-checkbox
                  checked="true"
                  v-model="reversalsCheck"
                  class="custom-control-dark  "
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
              <!-- empty accounts -->
              <b-form-group :label="$t('statement.display_empty_accounts')">
                <b-form-checkbox
                  checked="true"
                  v-model="emptyCheck"
                  class="custom-control-dark "
                  name="check-button"
                  switch
                >
                  <span class="switch-icon-left">
                    <feather-icon icon="CheckIcon" />
                  </span>
                  <span class="switch-icon-right">
                    <feather-icon icon="XIcon" />
                  </span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <!-- download pdf -->
            <b-col
              cols="12"
              md="12"
              xl="2"
              lg="2"
              sm="12"
              class="mt-1 text-right"
            >
              <b-overlay
                :show="loading"
                class="d-inline-block"
                spinner-small
                rounded="sm"
              >
                <b-button
                  v-if="statementEntries.length > 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  @click="downloadPdf(statementEntries)"
                  variant="outline-primary"
                  size="sm"
                >
                  <feather-icon icon="ArrowDownIcon" class="mr-50" />
                  <span class="align-middle">{{
                    $t("statement.pdf_download")
                  }}</span>
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
      <template #overlay v-if="downloadPDFloading">
        <div
          class="text-center d-flex justify-content-center align-items-start"
        >
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
          <p id="cancel-label">
            <feather-icon icon="TargetIcon" />
            {{ $t("general_text.download_pdf") }}
          </p>
        </div>
      </template>
    </b-overlay>
    <b-overlay :show="loading" rounded="sm" opacity="0.6" no-fade>
      <b-row class="match-height mt-2" v-if="statementHoldings.length > 0">
        <b-col md="3" lg="2" cols="12">
          <b-row>
            <b-col
              cols="12"
              @click="clickStatementAll()"
              class="cursor-pointer"
            >
              <statistic-card-statement
                :class="accountClass ? 'account_card' : ''"
                :statistic="accountNameSelected.AccountName"
                :statistic-title="accountNameSelected.AccountNumber"
                :color="tabColor"
              />
            </b-col>
            <b-col
              cols="12"
              v-for="(fetch, index) in statementHoldings"
              @click="clickStatement(fetch.AccountCode, index)"
              class="cursor-pointer"
              :key="index"
            >
              <statistic-card-statement
                :statistic="fetch.Underlying.toUpperCase()"
                :statistic-title="fetch.AccountCode.toUpperCase()"
                :startAmount="
                  fetch.TotalAmountDateStart
                    ? fetch.TotalAmountDateStart.toString()
                    : ''
                "
                :endAmount="
                  fetch.TotalAmountDateEnd
                    ? fetch.TotalAmountDateEnd.toString()
                    : ''
                "
                :totalAmount="
                  fetch.TotalAmountF ? fetch.TotalAmountF.toString() : ''
                "
                :totalAmountShowStart="startDate != null ? startDate : null"
                :totalAmountShowEnd="endDate != null ? endDate : null"
                :statistic-total="
                  fetch.TotalAmountF != null
                    ? fetch.TotalAmountF.toString()
                    : ''
                "
                :underlying="fetch.AccountCcy"
                :color="
                  singleAccountNumber.indexOf(fetch.AccountCode) != -1
                    ? 'light-success'
                    : 'white'
                "
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col md="9" lg="10" cols="12">
          <b-row>
            <b-col cols="12">
              <section id="card-actions">
                <b-row>
                  <!-- card actions -->

                  <b-col cols="12" v-if="statementEntries.length > 0">
                    <b-card-actions
                      v-for="(statement, index) in statementEntries"
                      :key="index"
                      action-collapse
                      :title="
                        statement.AccountCode + ' - ' + statement.Underlying
                      "
                      class="statement_card"
                    >
                      <b-row>
                        <b-col cols="12">
                          <b-table
                            class="compact_table"
                            :items="statement.statements"
                            :fields="tableColumns"
                            :small="true"
                            :hover="true"
                            :striped="true"
                            :fixed="false"
                            :bordered="true"
                            headVariant="dark"
                            table-variant="none"
                            :tbody-tr-class="rowClass"
                            responsive
                            :empty-text="
                              $t(
                                'statement.messages.not_matched_account_transactions'
                              )
                            "
                          >
                            <!--     fields: ['SFC_Uti', 'Date', 'Related Uti', 'Price', 'Type', 'Description', 'Currency', 'Amount', 'Balance'], -->
                            <template #cell(SFC_Uti)="data">
                              <div class="text-left">
                                {{ data.item.TcUti }}
                              </div>
                            </template>

                            <template v-if="tradeDateCheck" #cell(Date)="data">
                              <div class="text-left text-nowrap">
                                {{ shortDateValue(data.item.TradeDate) }}
                              </div>
                            </template>

                            <template v-else #cell(Date)="data">
                              <div class="text-left text-nowrap">
                                {{ shortDateValue(data.item.ValueDate) }}
                              </div>
                            </template>

                            <template #cell(Related_Uti)="data">
                              <div class="text-left">
                                {{ data.item.RelatedTcUti }}
                              </div>
                            </template>

                            <template #cell(Price)="data">
                              <div
                                class="text-left"
                                v-b-tooltip.hover.bottom="data.item.Price"
                              >
                                {{ moneyFixed8Decimal(data.item.Price) }}
                              </div>
                            </template>

                            <template #cell(Type)="data">
                              <div class="text-left">
                                {{ data.item.TransactionType }}
                              </div>
                            </template>

                            <template #cell(Description)="data">
                              <div class="text-left">
                                {{ data.item.EntryDescription }}
                              </div>
                            </template>

                            <template #cell(Currency)="data">
                              <div class="text-left">
                                {{ data.item.Underlying }}
                              </div>
                            </template>

                            <template #cell(Amount)="data">
                              <div
                                class="text-left"
                                v-if="data.item.AccountCode.includes('HOL')"
                              >
                                <span
                                  v-if="data.item.Amount >= 0"
                                  v-b-tooltip.hover.bottom="data.item.Amount"
                                  class="text-success"
                                  >{{ moneyFixed(data.item.Amount) }}</span
                                >
                                <span
                                  v-if="data.item.Amount < 0"
                                  v-b-tooltip.hover.bottom="data.item.Amount"
                                  class="text-danger"
                                  >{{ moneyFixed(data.item.Amount) }}</span
                                >
                              </div>
                              <div class="text-left" v-else>
                                <span
                                  v-if="data.item.Amount >= 0"
                                  v-b-tooltip.hover.bottom="data.item.Amount"
                                  class="text-success"
                                  >{{ moneyFixed(data.item.Amount) }}</span
                                >
                                <span
                                  v-if="data.item.Amount < 0"
                                  v-b-tooltip.hover.bottom="data.item.Amount"
                                  class="text-danger"
                                  >{{ moneyFixed(data.item.Amount) }}</span
                                >
                              </div>
                            </template>

                            <template #cell(Balance)="data">
                              <div
                                class="text-left"
                                v-if="data.item.AccountCode.includes('HOL')"
                              >
                                <span
                                  v-if="data.item.balance >= 0"
                                  v-b-tooltip.hover.bottom="data.item.balance"
                                  class="text-success"
                                  >{{ moneyFixed(data.item.balance) }}</span
                                >
                                <span
                                  v-if="data.item.balance < 0"
                                  v-b-tooltip.hover.bottom="data.item.balance"
                                  class="text-danger"
                                  >{{ moneyFixed(data.item.balance) }}</span
                                >
                              </div>
                              <div class="text-left" v-else>
                                <span
                                  v-if="data.item.balance >= 0"
                                  v-b-tooltip.hover.bottom="data.item.balance"
                                  class="text-success"
                                  >{{ moneyFixed(data.item.balance) }}</span
                                >
                                <span
                                  v-if="data.item.balance < 0"
                                  v-b-tooltip.hover.bottom="data.item.balance"
                                  class="text-danger"
                                  >{{ moneyFixed(data.item.balance) }}</span
                                >
                              </div>
                            </template>

                            <template #cell()="data">
                              <div class="text-center">
                                <b-button
                                  v-b-tooltip.hover.bottom="
                                    data.item.ProductType + ' Confirmation PDF'
                                  "
                                  variant="relief-primary"
                                  class="btn-icon"
                                  style="padding: 0.2rem 0.3rem;"
                                  @click="downloadConfirmation(data.item)"
                                  :disabled="
                                    data.item.RelatedTcUti.includes('CASH')
                                  "
                                >
                                  <feather-icon icon="FileTextIcon" />
                                </b-button>
                              </div>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-card-actions>
                  </b-col>
                  <b-col cols="12" v-else>
                    <b-alert variant="primary" show>
                      <div class="alert-body">
                        <span>{{
                          $t(
                            "statement.messages.not_matched_account_transactions"
                          )
                        }}</span>
                      </div>
                    </b-alert>
                  </b-col>
                </b-row>
              </section>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-col cols="12 mt-2" v-else>
        <b-alert variant="primary" show>
          <div class="alert-body">
            <span>{{
              $t("statement.messages.not_matched_account_transactions")
            }}</span>
          </div>
        </b-alert>
      </b-col>

      <template #overlay v-if="loading">
        <div
          class="text-center d-flex justify-content-center align-items-start mt-3"
        >
          <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
          <p id="cancel-label">
            <feather-icon icon="TargetIcon" />

            {{ $t("general_text.report_preparing") }}
          </p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import useStatementList from "./useStatementList";
import {
  BIcon,
  BButton,
  BFormCheckbox,
  BCalendar,
  VBTooltip,
  BTable,
  BOverlay,
  BBadge,
  BPagination,
  BFormSelect,
  BFormDatepicker,
  BCardBody,
  BCardHeader,
  BCardText,
  BCard,
  BRow,
  BCol,
} from "bootstrap-vue";
import store from "@/store";
import { avatarText } from "@core/utils/filter";
import { onUnmounted } from "@vue/composition-api";
import statementStoreModule from "./statementStoreModule";
import SettingsCard from "@core/components/statistics-cards/SettingsCard.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";

import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import StatisticCardStatement from "@core/components/statistics-cards/StatisticCardStatement.vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import moment from "moment";
import jsPDF from "jspdf";
import VueHtml2pdf from "vue-html2pdf";
import Ripple from "vue-ripple-directive";
import axiosIns from "@/libs/axios";

export default {
  components: {
    BIcon,
    BButton,
    BCardActions,
    BTable,
    BCalendar,
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BCardHeader,
    VueHtml2pdf,
    jsPDF,
    vSelect,
    SettingsCard,
    BFormDatepicker,
    BFormCheckbox,

    BFormSelect,
    BPagination,
    BBadge,
    BCardCode,
    ToastificationContent,
    StatisticCardStatement,
  },

  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  setup() {
    const STATEMENT_APP_STORE_MODULE_NAME = "statement";
    // Register module
    if (!store.hasModule(STATEMENT_APP_STORE_MODULE_NAME))
      store.registerModule(
        STATEMENT_APP_STORE_MODULE_NAME,
        statementStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STATEMENT_APP_STORE_MODULE_NAME))
        store.unregisterModule(STATEMENT_APP_STORE_MODULE_NAME);
    });

    const {
      tradeDateCheck,
      emptyCheck,
      reversalsCheck,
      singleAccountNumber,
      startDate,
      endDate,
      statementHoldings,
      statementEntries,
      accountNameSelected,
      fetchStatementHoldings,
      fetchStatementEntriesForAccountNumber,
      loading,
      costCenterSelected,
    } = useStatementList();

    const refreshStatement = () => {
      if (accountNameSelected) {
        // fetchStatementHoldings();
        fetchStatementEntriesForAccountNumber();
      }
    };

    return {
      fetchStatementHoldings,
      fetchStatementEntriesForAccountNumber,
      startDate,
      endDate,
      accountNameSelected,
      costCenterSelected,
      statementHoldings,
      statementEntries,
      loading,
      singleAccountNumber,
      reversalsCheck,
      emptyCheck,
      tradeDateCheck,
      refreshStatement,

      // Filter
    };
  },

  data() {
    var accountNames = [];
    var optCostCenters = [];

    return {
      accountNames,
      optCostCenters,
      // fields: this.tableColumns(),
      accountClass: true,
      downloadPDFloading: false,
    };
  },

  watch: {
    /* singleAccountNumber: function(val) {
      console.log(val);
    },*/
  },

  created() {
    this.getAccountNames();
  },

  methods: {
    getAccountNames(data) {
      store.dispatch("statement/getAccountNames", data).then((res) => {
        this.accountNames = res.data.resData;
        this.optCostCenters = res.data.costCenters;

      });
    },

    shortDateValue(dateValue) {
      if (dateValue != "") {
        return moment(dateValue).format("DD-MMM-YY");
      } else {
        return null;
      }
    },

    rowClass(item, type) {
      const colorClass = "table-info";
      if (!item || type !== "row") {
        return;
      }

      // eslint-disable-next-line consistent-return
      if (item.EntryReversed === "1") {
        return colorClass;
      }
    },

    moneyFixed(value) {
      if (value) {
        // Sayıyı 8 ondalık basamak olacak şekilde yuvarlayın
        let val = Number(value).toFixed(2);

        // Binlik ayıracı olarak virgül ekleyin
        let parts = val.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Onluk ve tam sayı kısmını birleştirerek geri dönün
        return parts.join(".");
      }
      return "0.00";
    },
    moneyFixed8Decimal(value) {
      if (value) {
        // Sayıyı 8 ondalık basamak olacak şekilde yuvarlayın
        let val = Number(value).toFixed(8);

        // Binlik ayıracı olarak virgül ekleyin
        let parts = val.split(".");
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Onluk ve tam sayı kısmını birleştirerek geri dönün
        return parts.join(".");
      }
      return "0.00";
    },

    downloadPdf(data) {
      this.downloadPDFloading = true;
      console.log(this.downloadPDFloading);
      //  console.log(this.$refs.downdloadToPdf.innerHTML);
      //const doc = new jsPDF();
      //pdfDownload

      data[0]["start"] = this.startDate;
      data[0]["end"] = this.endDate;

      store.dispatch("statement/downloadPdfGet", data).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          this.accountNameSelected.AccountName + " Report.pdf"
        );
        document.body.appendChild(link);
        link.click();
        this.downloadPDFloading = false;
      });
    },
    downloadConfirmation(item) {
      if (item && item.ProductType && item.RelatedTcUti) {
        this.downloadPDFloading = true;
        axiosIns
          .post(
            `statementTradeConfirmation`,
            {
              accountName: item.AccountName,
              transactionType: item.TransactionType,
              tcuti: item.RelatedTcUti,
              type: item.ProductType,
            },
            { responseType: "blob" }
          )
          .then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", item.TcUti + " Confirmation.pdf");
            document.body.appendChild(link);
            link.click();
            this.downloadPDFloading = false;
          })
          .catch((error) => {
            console.log(error);
            this.warningToastMessage(
              `Trade confirmation report error. ${error.message}. Please contact with your administrator`
            );
            this.downloadPDFloading = false;
          });
      } else {
        this.warningToastMessage(
          "Your trade dont have a product type and related uti. Trade needs to be product type and related uti for confirmation type. Please check your data."
        );
      }
    },

    clickStatement(value, index) {
      this.accountClass = false;
      const indexNumber = this.singleAccountNumber.indexOf(value);
      if (indexNumber == -1) {
        this.singleAccountNumber.push(value);
      } else {
        this.singleAccountNumber.splice(indexNumber, 1);
      }

      /*   if(this.singleAccountNumber.find((x) => x == value) == undefined){
                this.singleAccountNumber.push(value);
      }else{

      }
      */
    },

    clickStatementAll(value) {
      this.accountClass = true;
      this.singleAccountNumber = [];
    },
    warningToastMessage(val, timeout = 10000) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: val,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
  },

  computed: {
    tabColor: function() {
      if (this.singleAccountNumber.length == 0) {
        return "light-info";
      } else {
        return "white";
      }
    },
    tableColumns() {
      const columns = [
        "SFC_Uti",
        "Date",
        "Related_Uti",
        "Price",
        "Type",
        "Description",
        "Currency",
        "Amount",
        "Balance",
      ];

      if (this.accountNameSelected.AccountName !== "SFC P&L") {
        columns.push({
          key: "#",
          label: "#",
          class: "text-center",
        });
      }

      return columns;
    },
  },
};
</script>

<style>
.account_card {
  border: 1px dotted cyan;
}

.statement_card .card {
  margin-bottom: 0.5rem !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

// multiple vue select input
.v-select:not(.vs--single) .vs__selected {
  font-size: 0.6rem !important;
}
</style>

<!-- compact design scss code -->

<style lang="scss" scoped>
@import "~/src/assets/scss/compact-design.scss";

.card::v-deep .card-body {
  padding: 0.8rem 0.7rem !important;

  .card-title {
    font-weight: 500 !important;
    font-size: 1.285rem !important;
    margin-bottom: 0.6rem !important;
    text-decoration: none !important;
  }
}

// date picker
.compact_date_picker::v-deep .form-control {
  padding-left: 0.1rem !important;
  padding-top: 0.3rem !important;
}

// multiple vue select input
.compact_form_vue-multi-select::v-deep .vs__selected {
  position: relative !important;
}

//

.text-danger {
  font-size: 0.7rem;
  font-weight: bold;
}

.font-weight-bold {
  font-weight: 800 !important;
}
</style>
